import(/* webpackMode: "eager", webpackExports: ["GlobalProviders"] */ "/vercel/path0/apps/helio/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Chip/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/CircularProgress/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/Slider/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/ToggleButton/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/ToggleButtonGroup/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-axiom@1.4.1_next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_re_edcoqycuygyvvckpm7oaph7r5y/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["I18nClientProvider"] */ "/vercel/path0/packages/shared/i18n/src/provider/i18n-client.provider.tsx");
